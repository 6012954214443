<template>
  <div>
    <div class="c-infoList l-box">
      <div class="c-infoList__row head">
        <div class="c-infoList__cell date">更新日</div>
        <div class="c-infoList__cell title">タイトル</div>
      </div>
      <Info v-for="info in infos" :info="info" :key="info.id" />
    </div>
  </div>
</template>

<script>
import Info from "@/components/parts/Info";

export default {
  components: {
    Info,
  },
  props: {
    infos: {
      type: Array,
    },
  },
};
</script>
