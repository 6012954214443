<template>
  <div class="l-wrapper loggedin">
    <AppHeader pageTitle="お知らせ" />
    <AppSideMenu />
    <main class="l-main">
      <div class="l-full-white l-box">
        <div class="c-checkbox">
          <input
            id="isOnlyImportant-cb"
            type="checkbox"
            v-model="isOnlyImportant"
          />
          <label class="c-checkbox__label" for="isOnlyImportant-cb">
            <span class="c-checkbox__box"></span>
            重要のみ表示
          </label>
        </div>
      </div>
      <div class="l-container">
        <InfoList :infos="infos" />
        <Pagination
          :params="paginationParams"
          :page.sync="paginationParams.page"
          @callback="getInfos"
        />
      </div>
    </main>
    <AppFooter />
  </div>
</template>

<script>
import axios from "axios";
import InfoList from "./components/InfoList";
import Pagination from "@/components/parts/Pagination";
import { API_URL } from "@/const";
axios.defaults.headers.common["Content-Type"] = "application/json";

export default {
  name: "information",
  components: {
    InfoList,
    Pagination,
  },
  data() {
    return {
      infos: [],
      paginationParams: {
        page: this.$route.query.page ? +this.$route.query.page : 1,
        pageSize: this.$route.query.pageSize ? +this.$route.query.pageSize : 10,
        total: null,
      },
      sortsParams: {},
      isOnlyImportant: this.$route.query.isOnlyImportant === undefined ? true : (this.$route.query.isOnlyImportant === 'true'),
    };
  },
  watch: {
    isOnlyImportant() {
      this.getInfos();
    },
  },
  methods: {
    async getInfos() {
      await axios
        .get(
          `${API_URL.INFO.ALL}?isOnlyImportant=${this.isOnlyImportant}&page=${this.paginationParams.page}&pageSize=${this.paginationParams.pageSize}`
        )
        .then((res) => {
          this.infos = res.data.items;
          this.paginationParams = res.data.searchParams.pagination;
          this.sortsParams = res.data.searchParams.sorts;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  mounted() {
    this.getInfos();
  },
};
</script>
